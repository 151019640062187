<template>
  <div>
    <section class="iq-style1 py-4">
      <b-container>
        <b-row class="text-start mb-2">
          <b-col>
            <div class="d-flex align-items-center mb-5" style="gap: 10px">
              <curva-title :title="$t('productDetails.suggestProduct')" ></curva-title>
              <p class="text-gray m-0" style="font-size: 14px">( {{ total }} Results)</p>
            </div>

          </b-col>
        </b-row>
        <div class="d-flex flex-lg-row flex-column justify-content-between align-items-center">
          <div class="item-search mb-1 mb-lg-0">
            <input v-model="suggestProduct.price_from" type="number" class="form-control w-100 mb-0"
                   :placeholder="$t('search.priceFrom')"/>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <input v-model="suggestProduct.price_to" type="number" class="form-control w-100 mb-0"
                 :placeholder="$t('search.priceTo')"/>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select  class="w-100 mb-0" v-model.number="suggestProduct.category_id" :placeholder="$t('search.category')"
                       label="name" :reduce="data => data.id" :name="'categories'"
                       :options="categories" @change="getDependSubCategory">
          </main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select class="w-100 mb-0" v-model.number="suggestProduct.subcategory_id" :placeholder="$t('search.sub')"
                       label="subcat" :reduce="data => data.id" :name="'sub-categories'"
                       :options="subCategories">
          </main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
            <main-select  class="w-100 mb-0" v-model.number="suggestProduct.club_id" :placeholder="$t('search.club')"
                          label="name" :reduce="data => data.id" :name="'club'"
                          :options="clubs">
            </main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
            <main-select  class="w-100 mb-0" v-model.number="suggestProduct.brand_id" :placeholder="$t('search.brand')"
                          label="name" :reduce="data => data.id" :name="'brand'"
                          :options="brands">
            </main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select class="w-100 mb-0" v-model.number="suggestProduct.size_id" :placeholder="$t('search.size')"
                       :options="allSizes" label="name" :reduce="data => data.id"></main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
          <main-select class="w-100 mb-0" v-model.number="suggestProduct.branch_id" :placeholder="$t('search.branches')"
                       :options="branches" label="name"  :reduce="data => data.id" :name="'branches'"></main-select>
          </div>
          <div class="item-search mb-1 mb-lg-0">
            <b-button class="curva-granola-btn w-100 mb-0" block @click="getProductByCode">
              {{ $t('search.search') }}
            </b-button>
          </div>
        </div>
      </b-container>
    </section>
    <b-container class="pt-4" >
      <div v-if="!loading">
      <b-row v-if="products">
        <b-col lg="3" v-for="(product, key) in products" :key="key">
          <router-link :to="{name: 'productDetails', params: {id: product.info.product.id}}">
            <b-card
                class="product-card-container mb-5 cursor-pointer"
            >
              <div class="img-container  d-flex justify-content-center align-content-center">
                <img :src="product.info.image"/>
              </div>
              <b-card-body class="p-3 text-initial">
                <h4 style="white-space: nowrap; overflow: hidden;">{{ product.info.product.name }}</h4>
                <div style="display: flex; gap: 8px">
                  <p v-if="product.info.product.offer_price" class="font-size-18">
                  <span class="font-weight-bold text-warning">
                      {{ product.info.product.offer_price }}
                  </span>
                    <span class="font-size-12"> {{$t('egp')}}</span></p>
                  <p v-if="product.info.product.offer_price" class="text-decoration-line-through font-size-18 font-weight-bold text-secondary">
                    {{ product.info.product.init_price }} <span class="font-size-12">{{$t('egp')}}</span></p>
                  <p v-else class="font-size-18">
                    <span class="font-weight-bold text-warning"
                    >{{ product.info.product.init_price }}
                    </span>
                    <span class="font-size-12"> {{$t('egp')}}</span>
                  </p>
                </div>
                <b-row class="mb-3">
                  <b-col cols="4" class="text-gray">{{ $t('productDetails.branch') }}</b-col>
                  <b-col cols="8">{{product.branch.name}}</b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="4" class="text-gray">{{ $t('productDetails.availability') }}</b-col>
                  <b-col cols="8" >{{product.quantity}}</b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="4" class="text-gray">{{ $t('productDetails.size') }}</b-col>
                  <b-col cols="8" >{{product.info.size.name}}</b-col>
                </b-row>
                <b-row class="mb-3">
                  <b-col cols="4" class="text-gray">{{ $t('cart.barcode') }}</b-col>
                  <b-col cols="8">{{product.info.product_id}}</b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </router-link>
        </b-col>
        <b-col class="d-flex justify-content-center">
          <b-pagination
              class="curva-pagination"
              v-model="suggestProduct.page"
              :total-rows="total"
              :per-page="suggestProduct.limit"
              @input="getProductByCode"
          ></b-pagination>
        </b-col>
      </b-row>
        <p v-else class="text-center mt-4">
          {{ $t('empty') }}
      </p>
      </div>
      <div v-else class="text-center">
        <spinner-loading :text="'loading'"></spinner-loading>
      </div>
    </b-container>

  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import axios from 'axios'
import searchServices from '../services/search.services'
import CategoriesServices from '@/modules/categories/services/categories.services'
import ContactServices from '@/modules/contact-us/services/contact-us.services'
import aboutServices from '@/modules/about/services/about.services'
export default {
  data () {
    return {
      products: null,
      categories: [],
      allSizes: [],
      subCategories: [],
      branches: [],
      clubs: [],
      brands: [],
      loading: false,
      suggestProduct: {
        club_id: '',
        brand_id: '',
        price_from: '',
        price_to: '',
        category_id: '',
        subcategory_id: '',
        size_id: '',
        limit: 12,
        page: 1
      },
      total: 0
    }
  },
  watch: {
    suggestProduct: {
      handler () {
        this.updateQuery()
      },
      deep: true
    },
    'suggestProduct.subcategory_id': {
      handler (data) {
        this.getSizes(data)
      }
    }
  },
  methods: {
    getSizes (size) {
      searchServices.getSizes(size).then(response => {
        this.allSizes = response.data.data
      })
    },
    updateQuery () {
      this.$router.replace({
        name: this.$route.name,
        query: { ...this.suggestProduct }
      })
    },
    getProductByCode () {
      this.loading = true
      searchServices.suggestProducts(this.suggestProduct).then(res => {
        this.products = res.data.data.data
        this.loading = false
        this.suggestProduct.page = res.data.data.current_page
        this.suggestProduct.limit = res.data.data.per_page
        this.total = res.data.data.total
      })
    },
    getAllClubs () {
      aboutServices.getAllClubs().then(res => {
        this.clubs = res.data.data
      })
    },
    getAllBrands () {
      aboutServices.getAllBrands().then(res => {
        this.brands = res.data.data
      })
    },
    getAllCategories () {
      CategoriesServices.getCategories().then(res => {
        this.categories = res.data.data
      })
    },
    getDependSubCategory () {
      console.log('this.categories => ', this.categories)
      this.suggestProduct.subcategory_id = ''
      this.subCategories = this.categories.find(category => category.id === this.suggestProduct.category_id)?.sub_category || []
    },
    getAllBranches () {
      ContactServices.getContactInfo().then(res => {
        this.branches = res.data.data.branches
      })
    },
    applyInitialQuery () {
      if (Object.keys(this.$route.query).length) {
        this.suggestProduct = { ...this.suggestProduct, ...this.$route.query }
        this.getProductByCode()
      }
    }
  },
  created () {
    this.getAllCategories()
    this.getAllBranches()
    this.applyInitialQuery()
    this.getAllClubs()
    this.getAllBrands()
  },
  mounted () {
    core.index()
  }
}
</script>
<style>
.img-container {
  background-position: center;
}
.item-search {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  padding: 0 5px;
  width: 100%;
}
</style>
